<!-- 首页 -->
<template>
  <div class="Table">
    <Cardbox/>
    <!--    <Swipers />-->
    <Police/>
    <!-- 报警信息 -->
    <Warningmessage/>

  </div>
</template>

<script>
import Cardbox from './components/Cardbox'
import Swipers from './components/Swipers'
import Police from './components/Police'
import Warningmessage from './components/Warningmessage'

export default {
  data () {
    return {}
  },
  components: {
    Cardbox,
    Swipers,
    Police,
    Warningmessage
  },
}
</script>

<style lang="scss" scoped>
.Table {
  width: calc(446 / 1920 * 100vw);
  padding: calc(21 / 1920 * 100vw);
}
</style>
