<template>
  <!--  <div class="YearFlow card"-->
  <!--       v-loading="loading"-->
  <!--       element-loading-text="数据量过大，加载中...">-->
  <!--    <div class="TitlesR">-->
  <!--      <el-select v-model="flow" placeholder="请选择" size="mini" @change="flowChange" style="margin-left:10px">-->
  <!--        <el-option-->
  <!--          v-for="(item,index) in waterList"-->
  <!--          :key="item.deviceAddress"-->
  <!--          :label="index+1+' '+item.siteName"-->
  <!--          :value="item.deviceAddress">-->
  <!--        </el-option>-->
  <!--      </el-select>-->
  <!--    </div>-->

  <!--    <Titles titles="站点实时信息"/>-->
  <!--  </div>-->
  <div
    class="Police Card-b-c"
    v-loading="loading"
    element-loading-text="数据量过大，加载中..."
  >
    <div class="titlePolice">
      <el-select v-model="water" placeholder="请选择" size="mini" @change="waterChange"
                 style="margin-left:10px;width: 160px;">
        <el-option
          v-for="(item,index) in stationBzList"
          :key="item.value"
          :label="index+1+' '+item.label"
          :value="item.value">
        </el-option>
      </el-select>
    </div>
    <Titles titles="站点实时信息"/>

    <div class="sitedetails">
      <div class="WidthLeft">
        <div class="neironglect">
          <div class="wlifos">
            <p class="neirongt">闸前水位</p>
            <p class="neirong">{{ siteItemVo.gateLevel }}cm</p>
          </div>
          <div class="wlifos">
            <p class="neirongt">闸后水位</p>
            <p class="neirong">{{ siteItemVo.currentLevel }}cm</p>
          </div>
          <div class="wlifos">
            <p class="neirongt">B相电压</p>
            <p class="neirong">{{ siteItemVo.bVoltage }}V</p>
          </div>
          <div class="wlifos">
            <p class="neirongt">闸门孔数</p>
            <p class="neirong">{{ siteItemVo.gateNum }}孔</p>
          </div>
        </div>
      </div>
      <div class="WidthRight">
        <div class="neironglect">
          <div class="wlifos">
            <p class="neirongt">红外报警</p>
            <p v-if="alarmStatus.infraRedStatus==1" class="neirong" style="color: red">报警中</p>
            <p v-else class="neirong">正常</p>
          </div>
          <div class="wlifos">
            <p class="neirongt">门禁报警</p>
            <p v-if="alarmStatus.accessControlStatus==1" style="color: red" class="neirong">报警中</p>
            <p v-else class="neirong">正常</p>
          </div>
          <div class="wlifos">
            <p class="neirongt">存储设备</p>
            <p v-if="alarmStatus.storageDeviceStatus==1" style="color: red" class="neirong">报警中</p>
            <p v-else class="neirong">正常</p>
          </div>
          <div class="wlifos">
            <p class="neirongt">周界报警</p>
            <p v-if="alarmStatus.perimeterStatus==1" style="color: red" class="neirong">报警中</p>
            <p v-else class="neirong">正常</p>
          </div>
        </div>
      </div>
    </div>

    <!-- 注释掉的不要的 -->
    <div>
      <!--    <Titles-->
      <!--      Titles="测流点实时信息"-->
      <!--      isShow="true"-->
      <!--      :totals="totals"-->
      <!--      :policeTotal="policeTotal"-->
      <!--    >-->
      <!--    </Titles>-->

      <!--    <div class="NoData" v-if="totals == 0">-->
      <!--      暂无数据-->
      <!--    </div>-->
      <!--    <div v-else>-->
      <!--      <div v-if="noDisposeLists.length>=5" @click="clickPropsDetail($event)">-->
      <!--        <vue-seamless-scroll-->
      <!--          :data="noDisposeLists"-->
      <!--          :class-option="optionHover"-->
      <!--          class="seamless-warp"-->
      <!--        >-->
      <!--          <ul>-->
      <!--            <li-->
      <!--              class="flex flex-a-c flex-j-s-b"-->
      <!--              v-for="(item, i) in noDisposeLists"-->
      <!--              :Key="i"-->
      <!--            >-->
      <!--              <div class="Police-icon t-x font-w-b">-->
      <!--                {{ i + 1 }}-->
      <!--              </div>-->
      <!--              <div class="Police-text ">{{ item.meterName || item.name }}</div>-->
      <!--              <button-->
      <!--                class="Police-btn-yellow"-->
      <!--                :data-obj="JSON.stringify(item)"-->
      <!--                :id="i + 1"-->
      <!--                @click="handleDetail(item)"-->
      <!--              >-->
      <!--                查看详情-->
      <!--              </button>-->
      <!--            </li>-->
      <!--          </ul>-->
      <!--        </vue-seamless-scroll>-->
      <!--      </div>-->
      <!--      &lt;!&ndash; 未处理小于5条 &ndash;&gt;-->
      <!--      <div v-if="noDisposeLists.length<5" class="dispose_five">-->
      <!--        <ul>-->
      <!--          <li-->
      <!--            class="flex flex-a-c flex-j-s-b"-->
      <!--            v-for="(item, i) in noDisposeLists"-->
      <!--            :Key="i"-->
      <!--          >-->
      <!--            <div class="Police-icon t-x font-w-b">-->
      <!--              {{ i + 1 }}-->
      <!--            </div>-->
      <!--            <div class="Police-text ">{{ item.meterName || item.name }}</div>-->
      <!--            <button class="Police-btn" @click="handleDetail(item)">-->
      <!--              查看详情-->
      <!--            </button>-->
      <!--          </li>-->
      <!--        </ul>-->
      <!--      </div>-->

      <!--      &lt;!&ndash; <div v-if="disposeShow" class="dispose">-->
      <!--        <ul>-->
      <!--          <li-->
      <!--            class="flex flex-a-c flex-j-s-b"-->
      <!--            v-for="(item, i) in noDisposeLists"-->
      <!--            :Key="i"-->
      <!--          >-->
      <!--            <div class="Police-icon t-x font-w-b">-->
      <!--              <img src="../../../../../assets/images/index/red.png" />-->
      <!--            </div>-->
      <!--            <div class="Police-text ">{{ item.reason }}</div>-->
      <!--            <button class="Police-btn-yellow" @click="handleProce(item)">-->
      <!--              立即处理-->
      <!--            </button>-->
      <!--          </li>-->
      <!--        </ul>-->
      <!--        <div @click="clickPropsDetail($event)">-->
      <!--          <vue-seamless-scroll-->
      <!--            :data="disposeLists"-->
      <!--            :class-option="optionHover"-->
      <!--            class="seamless"-->
      <!--          >-->
      <!--            <ul>-->
      <!--              <li-->
      <!--                class="flex flex-a-c flex-j-s-b"-->
      <!--                v-for="(item, i) in disposeLists"-->
      <!--                :Key="i"-->
      <!--              >-->
      <!--                <div class="Police-icon t-x font-w-b">-->
      <!--                  <img src="../../../../../assets/images/index/green.png" />-->
      <!--                </div>-->
      <!--                <div class="Police-text " style="color:#333">-->
      <!--                  {{ item.reason }}-->
      <!--                </div>-->
      <!--                <button-->
      <!--                  class="Police-btn"-->
      <!--                  :data-obj="JSON.stringify(item)"-->
      <!--                  :id="i + 1"-->
      <!--                >-->
      <!--                  查看详情-->
      <!--                </button>-->
      <!--              </li>-->
      <!--            </ul>-->
      <!--          </vue-seamless-scroll>-->
      <!--        </div>-->
      <!--      </div> &ndash;&gt;-->
      <!--    </div>-->
      <!--    &lt;!&ndash; 弹框 &ndash;&gt;-->
      <!--    <el-dialog-->
      <!--      class="border-r-8 role-dialog"-->
      <!--      :visible.sync="addShow"-->
      <!--      @closed="closeBox"-->
      <!--      :destroy-on-close="true"-->
      <!--      :class="{ xiangQing: isActive }"-->
      <!--      width="calc(388/1920*100vw)"-->
      <!--    >-->
      <!--      <div slot="title" class="dialogTitle t-x font-s-18">{{ title }}</div>-->
      <!--      <div class="contentAdd">-->
      <!--        <el-form :model="alarmInfo" ref="ruleForm" :rules="rules">-->
      <!--          <el-form-item>-->
      <!--            <span class="label_r">测流点名称:</span>-->
      <!--            <el-input-->
      <!--              v-if="alarmInfo.meterName"-->
      <!--              v-model="alarmInfo.meterName"-->
      <!--              class="xiangQing"-->
      <!--              readonly-->
      <!--            ></el-input>-->
      <!--            <el-input-->
      <!--              v-if="alarmInfo.name"-->
      <!--              v-model="alarmInfo.name"-->
      <!--              class="xiangQing"-->
      <!--              readonly-->
      <!--            ></el-input>-->
      <!--          </el-form-item>-->
      <!--          <el-form-item prop="nickName">-->
      <!--            <span class="label_r">流速:</span>-->
      <!--            <el-input-->
      <!--              v-if="alarmInfo.flowSpeed"-->
      <!--              v-model="alarmInfo.flowSpeed+'（m/s）'"-->
      <!--              class="xiangQing"-->
      <!--              readonly-->
      <!--            ></el-input>-->
      <!--            <el-input-->
      <!--              v-if="alarmInfo.speed"-->
      <!--              v-model="alarmInfo.speed+'（m/s）'"-->
      <!--              class="xiangQing"-->
      <!--              readonly-->
      <!--            ></el-input>-->
      <!--          </el-form-item>-->
      <!--          <el-form-item>-->
      <!--            <span class="label_r">水位:</span>-->
      <!--            <el-input-->
      <!--              v-if="alarmInfo.waterLevel"-->
      <!--              v-model="alarmInfo.waterLevel+'（m）'"-->
      <!--              class="xiangQing"-->
      <!--              readonly-->
      <!--            ></el-input>-->
      <!--            <el-input-->
      <!--              v-if="alarmInfo.level"-->
      <!--              v-model="alarmInfo.level"-->
      <!--              class="xiangQing"-->
      <!--              readonly-->
      <!--            ></el-input>-->
      <!--          </el-form-item>-->
      <!--          <el-form-item>-->
      <!--            <span class="label_r">经度:</span>-->
      <!--            <el-input-->
      <!--              v-model="alarmInfo.lon"-->
      <!--              class="xiangQing"-->
      <!--              readonly-->
      <!--            ></el-input>-->
      <!--          </el-form-item>-->
      <!--          <el-form-item>-->
      <!--            <span class="label_r">纬度:</span>-->
      <!--            <el-input-->
      <!--              v-model="alarmInfo.lat"-->
      <!--              class="xiangQing"-->
      <!--              readonly-->
      <!--            ></el-input>-->
      <!--          </el-form-item>-->
      <!--          <el-form-item>-->
      <!--            <span class="label_r">瞬时流量:</span>-->
      <!--            <el-input-->
      <!--              v-model="alarmInfo.insFlow+'（m³/h）'"-->
      <!--              class="xiangQing"-->
      <!--              readonly-->
      <!--            ></el-input>-->
      <!--          </el-form-item>-->
      <!--          <el-form-item>-->
      <!--            <span class="label_r">累计流量:</span>-->
      <!--            <el-input-->
      <!--              v-if="alarmInfo.totalFlow"-->
      <!--              v-model="alarmInfo.totalFlow+'（m³）'"-->
      <!--              class="xiangQing"-->
      <!--              readonly-->
      <!--            ></el-input>-->
      <!--            <el-input-->
      <!--              v-if="alarmInfo.sumFlow"-->
      <!--              v-model="alarmInfo.sumFlow+'（m³/s）'"-->
      <!--              class="xiangQing"-->
      <!--              readonly-->
      <!--            ></el-input>-->
      <!--          </el-form-item>-->
      <!--          <el-form-item>-->
      <!--            <span class="label_r">采集时间:</span>-->
      <!--            <el-input-->
      <!--              v-if="alarmInfo.dataTime"-->
      <!--              v-model="alarmInfo.dataTime"-->
      <!--              class="xiangQing"-->
      <!--              readonly-->
      <!--            ></el-input>-->
      <!--            <el-input-->
      <!--              v-if="alarmInfo.caijiTime"-->
      <!--              v-model="alarmInfo.caijiTime"-->
      <!--              class="xiangQing"-->
      <!--              readonly-->
      <!--            ></el-input>-->
      <!--          </el-form-item>-->
      <!--        </el-form>-->
      <!--      </div>-->
      <!--      <span slot="footer" class="dialog-footer">-->
      <!--        </span>-->
      <!--    </el-dialog>-->
    </div>
  </div>
</template>
<script>
import Titles from '../../../../../components/Titles/index'
import { RecordList, getFlowMeteList, siteGetSiteAll } from '../../../../../api/index'
import { alarmLogImmediate } from '../../../../../api/index'

export default {
  created () {
    this.getData()
    this.showDown()

  },
  mounted () {

  },
  data () {
    return {
      isActive: false,
      rules: {
        description: [
          {
            required: true,
            message: '请输入处理详情',
            trigger: 'change'
          }
        ]
      },
      disposeLists: [], // 已处理
      noDisposeLists: [], // 未处理
      disposeShow: false,
      noDisposeShow: false,
      policeTotal: 0, //报警条数
      totals: 0, // 总条数
      addShow: false,
      title: '报警处理',
      typeList: [
        '水位报警',
        '电量报警',
        '设备报警',
        '门禁报警',
        '非法入侵报警'
      ],
      loading: false,
      fiveShow: false,
      alarmInfo: {},
      status: false, // 弹框类型
      waterList: [],//拦河闸下拉
      water: '',
      stationBzList: '',
      siteItemVo: '',
      alarmStatus: '', // 报警状态
    }
  },
  methods: {
    // 下拉框的数据获取
    showDown () {
      this.$get(`/site/page`, {
        page: 1,
        size: 9999
      })
        .then(res => {
          if (res.code == 200) {
            this.stationBzList = res.data.list.map(item => {
              return {
                value: item.id,
                label: item.siteName
              }
            })
            this.water = this.stationBzList[0].value
            //  根据id查询数据
            this.showDownid(this.stationBzList[0].value)
          }
        })
    },
    // 用户切换
    waterChange (val) {
      this.showDownid(val)
    },
    // 重新获取数据
    showDownid (val) {
      let params = {
        id: val
      }
      this.$get(`/site/getSiteById`, params).then((res) => {
        if (res.code == 200) {
          if (res.data) {
            this.siteItemVo = res.data.siteItemVo
            this.alarmStatus = res.data.alarmStatus
          }
        }
      })
    },
    clickPropsProce (e) {
      let index = e.target.id
      let item = JSON.parse(e.target.dataset.obj)
      this.handleProce(item)
    },
    clickPropsDetail (e) {
      let index = e.target.id
      let item = JSON.parse(e.target.dataset.obj)
      this.handleDetail(item)
    },
    // 处理数据
    getData () {
      this.loading = true
      getFlowMeteList().then(res => {
        this.noDisposeLists = res.data.ditchFlowMeters.concat(res.data.flowMeters)
        this.totals = this.noDisposeLists.length
        this.loading = false
      })

      // RecordList().then(res => {
      //  // this.policeTotal = res.data.noDisposeLists.length;
      //   this.totals =
      //     res.data.noDisposeLists.length + res.data.disposeLists.length;
      //   // 如果报警条数大于5
      //   if (res.data.noDisposeLists.length >= 5) {
      //     this.noDisposeLists = res.data.noDisposeLists.slice(0, 99);
      //     this.noDisposeShow = true;
      //     this.disposeShow = false;
      //   } else {
      //     if (this.totals <= 5 && res.data.disposeLists.length <= 1) {
      //       this.noDisposeLists = res.data.noDisposeLists;
      //       this.disposeLists = res.data.disposeLists;
      //       this.noDisposeShow = false;
      //       this.fiveShow = true;
      //     } else {
      //       this.fiveShow = false;
      //       this.noDisposeLists = res.data.noDisposeLists.slice(0, 3);
      //       this.disposeLists = res.data.disposeLists;
      //       this.noDisposeShow = false;
      //       this.disposeShow = true;
      //     }
      //   }

      // });
    },

    // 立即处理
    handleProce (item) {
      this.addShow = true
      this.title = '报警处理'
      this.status = true
      this.alarmInfo = item
      this.alarmInfo.type = this.typeList[item.type - 1]
      this.alarmInfo.userName = localStorage.getItem('userName')
    },
    handleDetail (item) {
      // console.log(item);
      this.addShow = true
      this.title = '测流点实时信息'
      this.status = false
      this.isActive = true
      this.alarmInfo = item
      this.alarmInfo.type = this.typeList[item.type - 1]
      this.alarmInfo.userName = localStorage.getItem('userName')
    },
    // 立即处理
    handleSave () {
      // 用户信息
      let {
        userId,
        userName
      } = JSON.parse(localStorage.getItem('userInfo'))
      // 报警信息
      let {
        description,
        id
      } = this.alarmInfo
      this.$refs['ruleForm'].validate(valid => {
        if (valid) {
          alarmLogImmediate({
            handlerId: userId,
            handlerName: userName,
            description,
            id
          })
            .then(res => {
              this.$message({
                message: res.message,
                type: 'success'
              })
              this.addShow = false
            })
            .catch(err => {
              this.$message({
                message: err.message,
                type: 'error'
              })
            })
            .finally(() => {
              this.getData()
            })
        }
      })
    },
    handleShow () {
      this.addShow = false
      this.alarmInfo = {}
    },
    closeBox () {
      this.$refs['ruleForm'].resetFields()
      this.isActive = false
    }
  },
  computed: {
    optionHover () {
      return {
        hoverStop: true,
        step: 0.4,
        limitMoveNum: 3
      }
    }
  },
  components: {
    Titles
  }
}
</script>

<style lang="scss" scoped>
.YearFlow {
  height: calc(265 / 1080 * 100vh);
  position: relative;
  @media screen and (max-height: 650px) {
    height: calc(268 / 1080 * 100vh);
  }
  @media screen and (max-height: 620px) {
    height: calc(270 / 1080 * 100vh);
  }

  .Flow {
    width: 100%;
    height: calc(210 / 1080 * 100vh);
  }

  .TitlesR {
    position: absolute;
    top: 8px;
    right: 5px;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}


.Police {
  position: relative;
  width: calc(410 / 1920 * 100vw);
  height: calc(295 / 1080 * 100vh);
  margin-top: calc(17 / 1920 * 100vw);

  ul {
    // height: calc(220 / 1080 * 100vh);
    overflow: hidden;

    li {
      margin: 0 calc(26 / 1920 * 100vw) calc(15 / 1080 * 100vh) calc(36 / 1920 * 100vw);
    }
  }

  .Police-icon {
    width: calc(25 / 1920 * 100vw);
    height: calc(23 / 1920 * 100vw);
    border-radius: 50%;
    background: #6BC162;
    line-height: calc(23 / 1920 * 100vw);
  }

  .Police-text {
    width: calc(234 / 1920 * 100vw);
    color: #333333;
    font-weight: bold;
    margin-left: calc(5 / 1920 * 100vw);
    font-size: calc(18 / 1920 * 100vw);
    overflow: hidden; //超出的文本隐藏
    text-overflow: ellipsis; //溢出用省略号显示
    white-space: nowrap; //溢出不换行
  }

  .Police-btn {
    width: calc(86 / 1920 * 100vw);
    height: calc(36 / 1080 * 100vh);
    border: 1px solid #e5e5e5;
    background: #0196ff;
    border-radius: 6px;
    color: #fff;
  }

  .Police-btn-yellow {
    width: calc(86 / 1920 * 100vw);
    height: calc(36 / 1080 * 100vh);
    border: 1px solid #e5e5e5;
    background: #fd7a23;
    border-radius: 6px;
    color: #fff;
  }

  .titlePolice {
    color: red;
    position: absolute;
    top: 7px;
    right: 6px;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  // 站点详情
  .sitedetails {
    width: 100%;
    height: 200px;
    background-color: #FFFFFF;
    display: flex;

    .WidthLeft {
      width: 50%;
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;


      .neironglect {
        width: 90%;
        height: 180px;
         background: rgba(240, 247, 255);
        border-radius: 10px;
        color: rgb(0, 117, 222);

        .wlifos {
          padding-top: 18px;
          display: flex;

          .neirongt {
            width: 100%;
            padding-left: 10px;
            font-size: 16px;
          }

          .neirong {
            width: 100%;
            text-align: right;
            padding-right: 10px;
            font-size: 16px;
          }
        }
      }
    }

    .WidthRight {
      width: 50%;
      height: 200px;
      display: flex;
      justify-content: center;
      align-items: center;

      .neironglect {
        width: 90%;
        height: 180px;
        background: rgba(240, 247, 255);
        border-radius: 10px;
        color: black;

        .wlifos {
          padding-top: 18px;
          display: flex;

          .neirongt {
            width: 100%;
            padding-left: 10px;
            font-size: 16px;
          }

          .neirong {
            width: 100%;
            text-align: right;
            padding-right: 10px;
            font-size: 16px;
            color: rgb(0, 117, 222);
          }
        }
      }

    }
  }
}

.seamless-warp {
  height: calc(210 / 1080 * 100vh);
  overflow: hidden;
}

.dispose {
  height: calc(245 / 1080 * 100vh);
  overflow: hidden;

  .seamless {
    overflow: hidden;
  }
}

.contentAdd {
  padding-top: 20px;
}

.el-input,
.el-textarea,
.el-select {
  width: calc(244 / 1920 * 100vw);
}

::v-deep .el-textarea {
  height: calc(117 / 1080 * 100vh) !important;

  .el-textarea__inner {
    height: 100%;
  }
}

//::v-deep .el-input__inner {
//  border: none;
//  border-bottom: calc(1 / 1920 * 100vw) #585858 solid;
//  border-radius: 0;
//}

::v-deep .el-form-item__content {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  align-items: flex-start;
}

::v-deep .el-dialog {
  background: #4073b6;
  border-radius: 16px;
  width: calc(388 / 1920 * 100vw) !important;

  .el-dialog__header {
    background: #fff;
    margin-top: 4px;
    border-radius: 16px 16px 0 0;
  }

  .el-dialog__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border-radius: 0 0 16px 16px;
    padding: calc(15 / 1080 * 100vh) 0;

    .el-button:nth-child(1):hover {
      background: #eaeaea;
      border: 1px #eaeaea solid;
      color: #313131;
    }
  }

  // 弹框主体
  .el-dialog__body {
    background: #fff;
    height: calc(395 / 1080 * 100vh);
    // 菜单选框
    .el-checkbox-group {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      label {
        width: 34% !important;
        height: calc(25 / 1080 * 100vh);
        margin-right: 0;
      }
    }
  }

  .el-input__inner {
    border: none;
    border-radius: 4px;
    background: #f3f4f8;
  }

  .label_r {
    margin-left: 20px !important;
    text-align: left;
    color: #666;
    width: calc(86 / 1920 * 100vw);
    // margin-bottom: 10px;
  }
}

::v-deep .xiangQing {
  .el-input__inner {
    background: #fff;
  }

  .el-textarea__inner {
    background: #fff;
    margin-top: calc(6 / 1080 * 100vh);
  }
}

::v-deep .el-dialog__header {
  border-bottom: 1px solid transparent;
  border-color: rgba(102, 102, 102, 0.1);
}

.NoData {
  font-size: calc(20 / 1920 * 100vw);
  color: #333;
  height: calc(245 / 1080 * 100vh);
  text-align: center;
  line-height: calc(245 / 1080 * 100vh);
}

.dispose_five {
  height: calc(245 / 1080 * 100vh);
  overflow: hidden;
}

.reason {
  .el-textarea {
    height: calc(58 / 1080 * 100vh) !important;
  }
}

.el-form-item {
  margin-bottom: 0;
}

.dialogTitle {
  color: #000;
}
</style>
