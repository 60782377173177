<template>
  <div class="Cardbox">
    <div class="Cardbox-header flex">
      <!-- 管辖区域 -->
      <div class="cardCom Card-b-c jurisdiction">
        <Titles titles="管辖区域(km²)"/>
        <div class="flex  textBox">
          <div style="width:calc(85/1920*100vw);height:calc(50/1080*100vh)">
            <p class="font-one font-w-b">66</p>
          </div>
        </div>
      </div>

      <!-- 河道数量 -->
      <div class="cardCom Card-b-c website ">
        <Titles titles="河道数量(条)"/>
        <div class="flex  textBox">
          <div style="width:calc(85/1920*100vw);height:calc(50/1080*100vh);">
            <p class="font-one font-w-b">{{ riversCount }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="Cardbox-header flex ">

      <!-- 节制闸数量 -->
      <div class="cardCom Card-b-c Brake" @click="handleProce(3)">
        <Titles titles="节制闸数量(个)"/>
        <div class="flex  textBox">
          <div style="width:calc(85/1920*100vw);height:calc(50/1080*100vh);cursor: pointer;">
            <p class="font-one font-w-b">{{ siteCount }}</p>
          </div>
        </div>
      </div>

      <!-- 测流点数量(个) -->
      <div class="cardCom Card-b-c vidicon">
        <Titles titles="测流点数量(个)"/>
        <div class="flex  textBox">
          <div style="width:calc(85/1920*100vw);height:calc(50/1080*100vh);">
            <p class="font-one font-w-b">{{ videoCount }}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 弹框 -->
    <el-dialog class="border-r-8 role-dialog" :visible.sync="addShow" @closed="closeBox" :destroy-on-close="true"
               width="calc(388/1920*100vw)">
      <div slot="title" class="dialogTitle t-x font-s-18">{{ title }}</div>
      <div class="contentAdd" v-for="(i,index) in alarmInfo" :key="index">
        <div class="Police-icon t-x font-w-b">
          {{ index + 1 }}
        </div>
        <div class="Police-text t-x font-w-b">
          {{ i.staName || i.siteName || i.meterName || i.name }}
        </div>
        <el-button class="Police-btn" type="text" @click="handleDetail(i,title)">
          查看详情
        </el-button>
      </div>
      <span slot="footer" class="dialog-footer">
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {getCountData, selectCountList} from '../../../../../api/index'
import Titles from '../../../../../components/Titles'
import axios from '../../../../../common/axiosHttp'

export default {
  data() {
    return {
      videoCount: '', //摄像机数量
      siteCount: '', //站点数量
      gateCount: '', //闸门数量
      title: '',
      addShow: false,
      alarmInfo: {},
      ulList: [],
      riversCount: '',
      pumpStationCount: '',
    }
  },
  mounted() {
    selectCountList().then(res => {
      this.videoCount = res.data.flowMeter //测流点数量
      this.siteCount = res.data.siteCount //拦河闸数量
      this.gateCount = res.data.pumpStationCount //泵站数量
    })
    getCountData().then(res => {
      this.riversCount = res.data.riversCount // 河道数量
      this.pumpStationCount = res.data.flowMeter
    })
  },
  methods: {
    // 获取拦河闸、泵站、测流点、河流数量
    getCounData() {
      this.$get(`/site/getCount`).then((res) => {
        console.log(res)
      })
    },
    handleProce(val) {
      if (val == 1) {
        this.title = '管辖区域'
        // this.show('./pumpStation/getPumpStations')
      } else if (val == 2) {
        this.title = '河道数量'
        // this.show('./site/findList')
      } else if (val == 3) {
        this.title = '节制闸数量'
        this.show('./site/findList')
        this.addShow = true
      } else if (val == 4) {
        this.title = '测流点数量'
      }
      // this.addShow = true

      //  this.alarmInfo = item
      // this.alarmInfo.type = this.typeList[item.type - 1]
      // this.alarmInfo.userName = localStorage.getItem('userName')
    },
    handleDetail(val, name) {
      console.log(val)
      if (name == '泵站数量') {
        this.$router.push(`/main/pumpStation/${val.id}/1-1`)
      } else if (name == '节制闸数量') {
        this.$router.push(`/main/StationManage/${val.id}`)
      } else if (name == '测流点数量') {
        this.$router.push(`/simpleness?lat=${val.lat}&lon=${val.lon}`)
      }
    },
    // 获取泵站列表
    show(val, num = 0) {
      if (num != 3) {
        axios.get(val).then(res => {
          // console.log(res.data);
          this.alarmInfo = res.data
        })
      } else {
        axios.get(val).then(res => {

          let ulList = res.data.ditchFlowMeters.concat(res.data.flowMeters)
          // console.log(ulList);
          this.alarmInfo = ulList
        })
      }
    },
    closeBox() {
      // this.$refs.ruleForm.resetFields()
      // this.isActive = false
    }
  },
  components: {
    Titles
  }
}
</script>

<style lang="scss" scoped>
.Cardbox {
  height: calc(285 / 1080 * 100vh);

  .Cardbox-header {
    .cardCom {
      width: calc(196 / 1920 * 100vw);
      height: calc(134 / 1080 * 100vh);
      margin: 0 calc(17 / 1920 * 100vw) calc(17 / 1920 * 100vw) 0;

      .textBox {
        position: relative;
        color: #487cbf;
        left: calc(35 / 1920 * 100vw);

        .font-one {
          font-size: calc(40 / 1920 * 100vw);
        }

        .font-two {
          font-size: calc(20 / 1920 * 100vw);
          position: absolute;
          bottom: calc(8 / 1080 * 100vh);
        }
      }

      .title {
        border-bottom: none;
      }
    }
  }

  // 管辖区域
  .jurisdiction {
    background: url("../../../../../assets/images/Cardbox/Jurisdiction.png") no-repeat;
    background-size: 100% 100%;
  }

  // 河道数量
  .website {
    background: url("../../../../../assets/images/Cardbox/rivercourse.png") no-repeat;
    background-size: 100% 100%;
  }

  // 节制闸数量
  .Brake {
    background: url("../../../../../assets/images/Cardbox/Controlgate.png") no-repeat;
    background-size: 100% 100%;
  }
  // 测流点数量
  .vidicon {
    cursor: pointer;
    background: url("../../../../../assets/images/Cardbox/Flowmeasuringpoint.png") no-repeat;
    background-size: 100% 100%;
  }
}

// 弹窗
::v-deep .el-dialog {
  border-radius: 16px;
  background: #4073b6;

  .el-dialog__header {
    border-radius: 16px 16px 0 0;
    margin-top: 4px;
    background: #fff;
  }

  .el-dialog__footer {
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 0 16px 16px;
    padding: calc(15 / 1080 * 100vh) 0;

    .el-button:nth-child(1):hover {
      border: 1px #eaeaea solid;
    }
  }

  // 弹框主体
  .el-dialog__body {
    background: #fff;
    height: calc(395 / 1080 * 100vh);
    // 菜单选框
    .el-checkbox-group {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      label {
        width: 34% !important;
        height: calc(25 / 1080 * 100vh);
        margin-right: 0;
      }
    }

    // 滚动条
    &::-webkit-scrollbar {
      width: 10px;
      background-color: #ccc;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 20px;
      background-color: rgb(155, 155, 155);
    }

    &::-webkit-scrollbar-track {
      // background-color: #333;
      background-color: #ccc;
    }
  }

  .el-dialog__headerbtn .el-dialog__close {
  }
}

.contentAdd {
  display: flex;
  padding: 10px 0px 15px 20px;
}

.Police-icon {
  width: calc(25 / 1920 * 100vw);
  height: calc(23 / 1920 * 100vw);
  border-radius: 50%;
  background: #6bc162;
  color: #fff;
  line-height: calc(23 / 1920 * 100vw);
}

.Police-text {
  text-align: left;
  width: calc(220 / 1920 * 100vw);
  font-weight: bold;
  margin-left: calc(20 / 1920 * 100vw);
  font-size: calc(18 / 1920 * 100vw);
  overflow: hidden; //超出的文本隐藏
  text-overflow: ellipsis; //溢出用省略号显示
  white-space: nowrap; //溢出不换行
}

.dialogTitle {
  margin-bottom: 10px;
  color: #333;
}

.Police-btn {
  color: #fd7a23;
  font-weight: bold;
}
</style>
