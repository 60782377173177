<!-- 报警信息 -->
<template>
  <div
    class="Police Card-b-c"
    v-loading="loading"
    element-loading-text="数据量过大，加载中..."
  >
    <Titles
      Titles="报警信息"
      isShow="true"
      :totals="totals"
      :policeTotal="policeTotal"
    >
    </Titles>

    <div class="callpolicest" style="color: black">
      <div class="topding">
        <p class="titleP">节制闸名称</p>
        <p class="titleP">报警类型</p>
        <p class="titleP">报警时间</p>
      </div>
      <div class="NoData" v-if="totals == 0">
        暂无数据
      </div>
      <div class="swpoerList" v-if="noDisposeLists.length>=5" @click="clickPropsDetail($event)">
        <vue-seamless-scroll
          :data="noDisposeLists"
          :class-option="optionHover"
          class="seamless-warp"
        >
          <ul>
            <li
              class="flex flex-a-c flex-j-s-b" style="cursor: pointer"
              v-for="(item, i) in noDisposeLists"
              :Key="i"  v-show="item.type=='3'" @click="toFacilityBack()">
              <div style="color: black" class="Police-text">{{ item.siteName || item.siteName }}</div>
              <div class="Police-text" style="width:130px;text-align:center;">{{ item.typeName }}</div>

              <div style="width:100px;padding-right: 0px" class="Police-text" :title="item.alarmTime"> {{ item.alarmTime }}</div>
            </li>
            <li
              class="flex flex-a-c flex-j-s-b" style="cursor: pointer"
              v-for="(item, i) in noDisposeLists"
              :Key="i" v-show="item.type!='3'"  @click="toStationManage(item.siteId)">
              <div style="color: black" class="Police-text">{{ item.siteName || item.siteName }}</div>
              <div class="Police-text" style="width:130px;text-align:center;">{{ item.typeName }}</div>

              <div style="width:100px;padding-right: 0px" class="Police-text" :title="item.alarmTime"> {{ item.alarmTime }}</div>
            </li>
          </ul>
        </vue-seamless-scroll>
        <!--        <ul>-->
        <!--          <li><span>节制闸名称</span><span style="padding-left: 74px">报警类型</span><span style="padding-left: 74px">报警时间</span></li>-->
        <!--        </ul>-->
      </div>
    </div>


    <div>
      <!--            <div v-if="noDisposeLists.length>=5" @click="clickPropsDetail($event)">-->
      <!--              <vue-seamless-scroll-->
      <!--                :data="noDisposeLists"-->
      <!--                :class-option="optionHover"-->
      <!--                class="seamless-warp"-->
      <!--              >-->
      <!--                <ul>-->
      <!--                  <li-->
      <!--                    class="flex flex-a-c flex-j-s-b"-->
      <!--                    v-for="(item, i) in noDisposeLists"-->
      <!--                    :Key="i"-->
      <!--                  >-->
      <!--                    <div class="Police-icon t-x font-w-b">-->
      <!--                      {{ i + 1 }}-->
      <!--                    </div>-->
      <!--                    <div style="color: black" class="Police-text ">{{ item.siteName || item.siteName }}</div>-->
      <!--                    <button-->
      <!--                      v-if="item.status==2"-->
      <!--                      class="Police-btn-yellow"-->
      <!--                      :data-obj="JSON.stringify(item)"-->
      <!--                      :id="i + 1"-->
      <!--                      @click="handleDetail(item,1)">-->
      <!--                      查看详情-->
      <!--                    </button>-->

      <!--                    <button-->
      <!--                      v-else-->
      <!--                      class="Police-btn-yellow"-->
      <!--                      :data-obj="JSON.stringify(item)"-->
      <!--                      :id="i + 1"-->
      <!--                      @click="handleDetail(item,2)">-->
      <!--                      立即处理-->
      <!--                    </button>-->
      <!--                  </li>-->
      <!--                </ul>-->
      <!--              </vue-seamless-scroll>-->
      <!--            </div>-->
    </div>

    <!-- 弹框 -->
    <el-dialog
      class="border-r-8 role-dialog"
      :visible.sync="addShow"
      @closed="closeBox"
      :destroy-on-close="true"
      :class="{ xiangQing: isActive }"
    >
      <div slot="title" style="color: black" class="dialogTitle t-x font-s-18">{{ title }}</div>
      <div class="contentAdd" style="margin-bottom: 10px">
        <el-form :model="alarmInfo" ref="ruleForm" :rules="rules">
          <el-form-item>
            <span class="label_r">报警类型</span>
            <el-input
              v-model="alarmInfo.type"
              class="xiangQing"
              readonly
            ></el-input>
          </el-form-item>
          <el-form-item prop="nickName">
            <span class="label_r">报警时间</span>
            <el-input
              v-model="alarmInfo.alarmTime"
              class="xiangQing"
              readonly
            ></el-input>
          </el-form-item>
          <el-form-item>
            <span class="label_r">报警处理人</span>
            <el-input
              v-model="alarmInfo.userName"
              class="xiangQing"
              readonly
            ></el-input>
          </el-form-item>
          <el-form-item prop="nickName" class="reason">
            <span class="label_r">报警原因</span>
            <el-input
              v-model="alarmInfo.reason"
              class="xiangQing"
              resize="none"
              type="textarea"
              readonly
            ></el-input>
          </el-form-item>
          <el-form-item prop="description">
            <span class="label_r">处理详情</span>
            <el-input
              type="textarea"
              resize="none"
              v-model="alarmInfo.description"
              :readonly="!status"
              maxlength="200"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span v-if="status" slot="footer" class="dialog-footer">
        <el-button @click="handleShow" class="guanBi">关闭</el-button>
        <el-button
          type="primary"
          @click="handleSave"
          style="margin-left:calc(30/1920*100vw)"
        >保存</el-button
        >
      </span>
      <span v-else slot="footer" class="dialog-footer">
        <el-button @click="handleShow" class="guanBi" type="primary"
        >关闭</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Titles from '../../../../../components/Titles/index'
import { RecordList, getFlowMeteList } from '../../../../../api/index'
import { alarmLogImmediate } from '../../../../../api/index'

export default {
  created () {
    // this.getData()
    this.baojing()
  },
  data () {
    return {
      isActive: false,
      rules: {
        description: [
          {
            required: true,
            message: '请输入处理详情',
            trigger: 'blur'
          }
        ]
      },
      disposeLists: [], // 已处理
      noDisposeLists: '', // 未处理
      disposeShow: false,
      noDisposeShow: false,
      policeTotal: 0, //报警条数
      totals: 0, // 总条数
      addShow: false,
      title: '报警处理',
      typeList: [
        '水位报警',
        '电量报警',
        '设备报警',
        '门禁报警',
        '非法入侵报警'
      ],
      loading: false,
      fiveShow: false,
      alarmInfo: {},
      status: false // 弹框类型
    }
  },
  methods: {
    // 报警类型转换
    tableDataList () {
      this.noDisposeLists.forEach(item => {
        // console.log(item);
        if (item.type == '1') {
          var arr=item.reason.split('超限')
          item.typeName = '水位报警超限'+arr[1]
        }
        if (item.type == '2') {
          item.typeName = '电量报警'
        }
        if (item.type == '3') {
          item.typeName = '设备报警'
        }
        if (item.type == '4') {
          item.typeName = '非法入侵报警'
        }
        if (item.type == '5') {
          item.typeName = '非法入侵报警'
        }
      })
    },
    // 跳转去节制闸
    toStationManage(siteId){
       this.$router.push({name:'StationManage',params:{siteId:siteId}});
    },
    // 调整去设备追回
    toFacilityBack(){
       this.$router.push({name:'FacilityBack'});
    },
    // 调取报警信息
    baojing () {
      let hhh = []
      this.$get(`/alarmRecord/page/${1}/${9999}`, {}).then(res => {
        this.noDisposeLists = res.data.list
        // console.log(this.noDisposeLists)
        this.totals = res.data.list.length
        this.tableDataList()
      })
    },
    clickPropsProce (e) {
      let index = e.target.id
      let item = JSON.parse(e.target.dataset.obj)
      // this.handleProce(item)
    },
    clickPropsDetail (e) {
      let index = e.target.id
      // let item = JSON.parse(e.target.dataset.obj)
      // this.handleDetail(item)
    },
    // 立即处理
    handleProce (item) {
      this.addShow = true
      this.title = '报警处理'
      this.alarmInfo = item
      this.alarmInfo.type = this.typeList[item.type - 1]
      this.alarmInfo.userName = localStorage.getItem('userName')
      console.log(this.alarmInfo)
    },
    // 查看详情
    handleDetail (item, type) {
      this.title = type == 1 ? '报警详情' : '报警处理'
      // 详情
      if (type == 1) {
        this.addShow = false
        this.isActive = true
      } else {
        // 处理
        this.addShow = true
        this.status = true
        this.alarmInfo = item
      }
      this.alarmInfo = JSON.parse(JSON.stringify(item))
      this.alarmInfo.userName = localStorage.getItem('userName')
      this.addShow = true
    },
    // 立即处理
    handleSave () {
      // 用户信息
      let {
        userId,
        userName
      } = JSON.parse(localStorage.getItem('userInfo'))
      // 报警信息
      let {
        description,
        id
      } = this.alarmInfo
      this.$refs['ruleForm'].validate(valid => {
        if (valid) {
          alarmLogImmediate({
            handlerId: userId,
            handlerName: userName,
            description,
            id
          })
            .then(res => {
              this.$message({
                message: res.message,
                type: 'success'
              })
              this.addShow = false
            })
            .catch(err => {
              this.$message({
                message: err.message,
                type: 'error'
              })
            })
            .finally(() => {
              this.getData()
            })
        }
      })
    },
    // 关闭
    handleShow () {
      this.addShow = false
      this.alarmInfo = {}
      this.closeBox()
    },
    // 关闭清除表单验证
    closeBox () {
      this.$refs['ruleForm'].resetFields()
      this.isActive = false
      this.$refs.ruleForm.resetFields()
    }
  },
  computed: {
    optionHover () {
      return {
        hoverStop: true,
        step: 0.4,
        limitMoveNum: 3
      }
    }
  },
  components: {
    Titles
  }
}
</script>

<style lang="scss" scoped>
.Police {
  width: calc(410 / 1920 * 100vw);
  height: calc(295 / 1080 * 100vh);
  margin-top: calc(17 / 1920 * 100vw);

  ul {
    // height: calc(220 / 1080 * 100vh);
    overflow: hidden;

    li {
      margin: 0 calc(10 / 1920 * 100vw) calc(10 / 1080 * 100vh) calc(10 / 1920 * 100vw);
    }
  }

  .Police-icon {
    width: calc(25 / 1920 * 100vw);
    height: calc(23 / 1920 * 100vw);
    border-radius: 50%;
    background: #6BC162;
    line-height: calc(23 / 1920 * 100vw);
  }

  .Police-text {
    width: 110px;
    color: #333333;
    //font-weight: bold;
    margin-left: calc(5 / 1920 * 100vw);
    font-size: calc(14 / 1920 * 100vw);
    overflow: hidden; //超出的文本隐藏
    text-overflow: ellipsis; //溢出用省略号显示
    white-space: nowrap; //溢出不换行
  }

  .Police-btn {
    width: calc(86 / 1920 * 100vw);
    height: calc(36 / 1080 * 100vh);
    border: 1px solid #e5e5e5;
    background: #0196ff;
    border-radius: 6px;
    color: #fff;
  }

  .Police-btn-yellow {
    width: calc(86 / 1920 * 100vw);
    height: calc(36 / 1080 * 100vh);
    border: 1px solid #e5e5e5;
    background: #fd7a23;
    border-radius: 6px;
    color: #fff;
  }

  .callpolicest {
    .topding {
      width: 100%;
      background-color: rgb(257, 247, 247);
      height: 30px;
      line-height: 30px;
      display: flex;
      justify-content: space-between;
      border: 1px solid rgb(236, 236, 236);
      border-left: none;
      border-right: none;
      text-align: center;
      p:nth-child(1){
        margin-left: 20px;
      }
      p:nth-child(3){
        margin-right: 30px;
      }
      .titleP {
        font-size: 16px;
        // padding-left: 30px;
        // padding-right: 30px;
      }
    }

    .swpoerList {
      li {
        height: 30px;
        line-height: 30px;
      }
    }
  }

}

.seamless-warp {
  height: calc(210 / 1080 * 100vh);
  overflow: hidden;
}

.dispose {
  height: calc(245 / 1080 * 100vh);
  overflow: hidden;

  .seamless {
    overflow: hidden;
  }
}

.contentAdd {
  padding-top: 20px;
}

.el-input,
.el-textarea,
.el-select {
  width: calc(244 / 1920 * 100vw);
}

::v-deep .el-textarea {
  height: calc(117 / 1080 * 100vh) !important;

  .el-textarea__inner {
    height: 100%;
  }
}

::v-deep .el-input__inner {
  border: none;
  border-bottom: calc(1 / 1920 * 100vw) #585858 solid;
  border-radius: 0;
}

::v-deep .el-form-item__content {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  align-items: flex-start;
}

::v-deep .el-dialog {
  background: #4073b6;
  border-radius: 16px;
  width: calc(388 / 1920 * 100vw) !important;

  .el-dialog__header {
    background: #fff;
    margin-top: 4px;
    border-radius: 16px 16px 0 0;
  }

  .el-dialog__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border-radius: 0 0 16px 16px;
    padding: calc(15 / 1080 * 100vh) 0;

    .el-button:nth-child(1):hover {
      background: #eaeaea;
      border: 1px #eaeaea solid;
      color: #313131;
    }
  }

  // 弹框主体
  .el-dialog__body {
    background: #fff;
    height: calc(395 / 1080 * 100vh);
    // 菜单选框
    .el-checkbox-group {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      label {
        width: 34% !important;
        height: calc(25 / 1080 * 100vh);
        margin-right: 0;
      }
    }
  }

  .el-input__inner {
    border: none;
    border-radius: 4px;
    background: #f3f4f8;
  }

  .label_r {
    margin-left: 20px !important;
    text-align: left;
    color: #666;
    width: calc(86 / 1920 * 100vw);
    // margin-bottom: 10px;
  }
}

::v-deep .xiangQing {
  .el-input__inner {
    background: #fff;
  }

  .el-textarea__inner {
    background: #fff;
    margin-top: calc(6 / 1080 * 100vh);
  }
}

::v-deep .el-dialog__header {
  border-bottom: 1px solid transparent;
  border-color: rgba(102, 102, 102, 0.1);
}

.NoData {
  font-size: calc(20 / 1920 * 100vw);
  color: #333;
  height: calc(245 / 1080 * 100vh);
  text-align: center;
  line-height: calc(245 / 1080 * 100vh);
}

.dispose_five {
  height: calc(245 / 1080 * 100vh);
  overflow: hidden;
}

.reason {
  .el-textarea {
    height: calc(58 / 1080 * 100vh) !important;
  }
}

.el-form-item {
  margin-bottom: 0;
}

.dialogTitle {
  color: #000;
}
</style>
